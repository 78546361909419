import axios from '@axios'

export default function subActivitiesServices() {
  const getEvent = async (queryParams) => {
    const res = await axios.get('/event', {
      params: queryParams,
    })

    return res.data.response
  }
  const getSubActivities = async (queryParams) => {
    const res = await axios.get('/event/blocks', {
      params: queryParams,
    })
    return res
  }

  const create = async (queryParams) => {
    const res = await axios.post('/events/blocks/create', queryParams)
    return res
  }

  const edit = async (queryParams) => {
    const res = await axios.post('/events/blocks/update', queryParams)
    return res
  }

  const del = async (queryParams) => {
    const res = await axios.post('/events/blocks/delete', queryParams)
    return res
  }

  const getSpeakers = async (queryParams) => {
    const res = await axios.get('/event/speakers', {
      params: queryParams,
    })
    return res.data.response.speakers
  }

  const getSpeakerBlock = async (queryParams) => {
    const res = await axios.post('/events/speaker/block', queryParams)
    return res.data.response.speakers
  }

  const updateSpeaker = async (queryParams) => {
    const res = await axios.post('/events/blocks/speakers/create', queryParams)
    return res
  }

  const fetchSpeaker = async (queryParams) => {
    const res = await axios.get('/speakers', { params: queryParams })

    return res.data
  }

  return {
    getEvent,
    getSubActivities,
    create,
    edit,
    del,
    getSpeakers,
    getSpeakerBlock,
    updateSpeaker,
    fetchSpeaker,
  }
}
