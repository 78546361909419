/* eslint-disable no-restricted-globals */
<template>
  <b-sidebar
    id="add-new-event-sidebar"
    :visible="isEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-event-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Editar sub-atividade
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Title -->
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-group
              label="Título"
              label-for="title"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="title"
                v-model="form.title"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-row>
            <b-col>
              <!--  Start Time -->
              <validation-provider
                v-slot="{ errors }"
                ref="start_time"
                name="start_time"
                rules="required|same_or_after:start_time"
              >
                <b-form-group
                  label="Hora Início"
                  label-for="start_time"
                  :state="errors.length > 0 ? false : null"
                >
                  <vue-timepicker
                    id="start_time"
                    ref="start_time"
                    v-model="form.start_time"
                    input-class="form-control"
                    close-on-complete
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- End Time -->
              <validation-provider
                v-slot="{ errors }"
                name="end_time"
                rules="required|same_or_before:end_time|time_new_gt:start_time"
              >
                <b-form-group
                  label="Hora Fim"
                  label-for="end_time"
                  :state="errors.length > 0 ? false : null"
                >
                  <vue-timepicker
                    id="end_time"
                    v-model="form.end_time"
                    input-class="form-control"
                    close-on-complete
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isBusy"
            >
              <b-spinner v-if="isBusy" />
              <template v-else>
                Salvar
              </template>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker/src'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required, min_value as minValue, email, integer, required_if, min, confirmed
} from 'vee-validate/dist/rules'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import subActivitiesServices from './subActivitiesServices'
import vSelect from 'vue-select'

extend('confirmed', {
  ...confirmed,
  message: 'Senha de confirmação diferente da senha.',
})

extend('min', {
  ...min,
  message: 'Mínimo de caracteres são 6.',
})

extend('required_if', {
  ...required_if,
  message: 'Esse campo é obrigatório.',
})

extend('integer', {
  ...integer,
  message: 'Esse aceita apenas números.',
})

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('email', {
  ...email,
  message: 'Digite um email válido.',
})

extend('min_length_deposit', {
  ...minValue,
  message: 'O valor minímo para depositar é R$ 1,00.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // vSelect,
    // Datepicker,
    VueTimepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isBusy: false,
      ptBR,
      form: {
        id: null,
        title: null,
        start_time: null,
        end_time: null,
        event_id: null,
      },
    }
  },
  computed: {},
  watch: {},
  created() {
    extend('time_new_gt', {
      validate: this.validateTimeNew,
      message: 'Hora Fim deve ser maior que Hora Início',
    })

    extend('same_or_after', {
      validate: this.validateSameOrAfter,
      message: 'O horario de início deve estar dentro do horario do evento.',
    })

    extend('same_or_before', {
      validate: this.validateSameOrBefore,
      message: 'O horario de término deve estar dentro do horario do evento.',
    })
  },
  mounted() {
    this.form.id = this.data.id
    this.form.title = this.data.title
    this.form.start_time = this.data.start_time
    this.form.end_time = this.data.end_time
  },
  methods: {
    validateTimeNew(value, start_time) {
      if (!this.isBusy) {
        if (Number.isNaN(this.$data.form[start_time].HH) || Number.isNaN(value.HH)) {
          return false
        }

        const endTime = this.$moment(`${value}`, 'HH:mm a')
        const startTime = this.$moment(`${this.$data.form[start_time]}`, 'HH:mm a')

        return endTime.isAfter(startTime)
      }
      return true
    },
    validateSameOrAfter(value, start_time) {
      if (!this.isBusy) {
        if (Number.isNaN(this.$data.form[start_time].HH) || Number.isNaN(value.HH)) {
          return false
        }

        const eventTime = this.$moment(`${this.event.start_time}`, 'HH:mm a')
        const startTime = this.$moment(`${this.$data.form[start_time]}`, 'HH:mm a')

        return startTime.isSameOrAfter(eventTime)
      }
      return true
    },
    validateSameOrBefore(value, end_time) {
      if (!this.isBusy) {
        if (Number.isNaN(this.$data.form[end_time].HH) || Number.isNaN(value.HH)) {
          return false
        }

        const eventTime = this.$moment(`${this.event.end_time}`, 'HH:mm a')
        const endTime = this.$moment(`${this.$data.form[end_time]}`, 'HH:mm a')

        return endTime.isSameOrBefore(eventTime)
      }
      return true
    },
    resetForm() {
      this.form = {
        title: null,
        start_time: null,
        end_time: null,
        event_id: null,
      }
      this.$emit('close')
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            this.isBusy = true

            this.form.start_time = `${this.form.start_time}:${this.form.start_time}:00`
            this.form.end_time = `${this.form.end_time}:${this.form.end_time}:00`
            this.form.event_id = this.$route.params.id

            subActivitiesServices().edit(this.form).then((api) => {
              if (api.data.status === 400) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: api.data.message,
                    variant: 'danger',
                  }
                })
              } else if (api.data.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('refresh')
                this.$emit('onSaved', true)
              }
            }).catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: err.message,
                  variant: 'danger',
                }
              })
            })
              .finally(() => { this.isBusy = false })
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-congress-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
