/* eslint-disable import/no-unresolved */
<template>
  <section id="sub-activities">
    <div>
      <add-new
        v-if="isAddNewSidebarActive"
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        :event="event"
        @refresh="refresh"
        @refetch-data="refetchData"
        @close="isAddNewSidebarActive = false"
      />

      <edit
        v-if="isEditSidebarActive"
        :is-edit-sidebar-active.sync="isEditSidebarActive"
        :event="event"
        :data="rowData"
        @refresh="refresh"
        @refetch-data="refetchData"
        @close="isEditSidebarActive = false"
      />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="5"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>sub-atividades</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="isAddNewSidebarActive = true"
                >
                  <span class="text-nowrap">Nova sub-atividade</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="overflow-auto">
          <b-table
            id="my-table"
            ref="my-table"
            class="position-relative"
            :items="items"
            :fields="tableColumns"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column: Role -->
            <template #cell(role)="data">
              <div class="text-nowrap">
                <feather-icon
                  :icon="resolveUserRoleIcon(data.item.role)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveUserRoleVariant(data.item.role)}`"
                />
                <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="edit(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="openSpeaker(data.item.id)">
                  <feather-icon icon="UserPlusIcon" />
                  <span class="align-middle ml-50">Palestrante</span>
                </b-dropdown-item>

                <b-dropdown-item>
                  <feather-icon icon="Share2Icon" />
                  <span class="align-middle ml-50">Compartilhar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="del(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <!-- <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }}</span> -->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>

    <!-- Modal speaker -->
    <b-modal
      id="bv-modal-palestrante"
      hide-footer
      title="Adicionar palestrantes"
    >
      <form role="form">
        <div class="form-group">
          <label for="speaker">Lista de palestrantes</label>
          <div>
            <div>
              <div
                v-for="item in selectedSpeaker"
                :key="item.name"
              >
                {{ item.name_badge }}
              </div>
              <br>
              <label
                class="typo__label"
              >Selecione palestrante</label>
              <multiselect
                v-model="selectEventBlockspeakers"
                :options="speakers"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :max="7"
                :preserve-search="true"
                placeholder="Selecione"
                label="name_badge"
                track-by="name_badge"
                :preselect-first="false"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, isOpen }"
                >
                  <span
                    v-if="values.length &amp;&amp; !isOpen"
                    class="multiselect__single"
                  >{{ values.length }} options selected</span>
                </template>
              </multiselect>
            </div>
          </div>
          <div style="margin-top: 30px;">
            <button
              class="btn btn-success btn-sm float-left m-t-n-xs"
              type="submit"
              @click.prevent="updateSpeaker()"
            >
              Salvar alteração
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useList from './useList'
import subActivitiesServices from './subActivitiesServices'
import AddNew from './AddNew.vue'
import Edit from './Edit.vue'
import Multiselect from 'vue-multiselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'

export default {

  components: {
    AddNew,
    Edit,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    Multiselect,

    vSelect,
  },
  setup() {
    const isAddNewSidebarActive = ref(false)
    const isEditSidebarActive = ref(false)

    const {
      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewSidebarActive,
      isEditSidebarActive,

      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  data() {
    return {
      speakers: [],
      perPage: 10,
      currentPage: 1,
      totalSpeakerEvent: 0,
      list: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      items: [],
      searchValue: null,
      rowData: {},
      event: {},
      selectedSpeaker: [],
      selectEventBlockspeakers: [],
      value: [],
      blockSpeakersSelect: {},
      formSpeakers: [],
      formBlockSpeakers: {
        events_blocks_id: '',
        speakers: [],
      },
      eventSpeaker: [],
      tableColumns: [
        {
          key: 'id',
          label: 'Código',
          sortable: true
        },
        {
          key: 'title',
          label: 'Título',
          sortable: true,
        },
        {
          key: 'start_time',
          label: 'Hor. Inicial',
          sortable: true,
        },
        {
          key: 'end_time',
          label: 'Hor. Fim',
          sortable: true,
        },
        {
          key: 'start_time',
          label: 'Horario Inicial',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Ações',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    rows() {
      return this.items.length
    },
  },
  watch: {},
  async mounted() {
    this.listSpeakers()

    await subActivitiesServices().getEvent({ id: this.$route.params.id }).then(res => {
      this.event = res.event
    })

    await subActivitiesServices().getSubActivities({ event_id: this.$route.params.id }).then(api => {
      this.items = api.data.response.blocks
    })
  },
  methods: {
    edit(data) {
      this.rowData.id = data.id
      this.rowData.start_time = data.start_time
      this.rowData.end_time = data.end_time
      this.rowData.title = data.title
      this.isEditSidebarActive = true
    },
    del(id) {
      this.$swal({
        title: 'Deletar sub-atividade?',
        text: 'Você não será capaz de reverte essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3b65f0',
        cancelButtonColor: '#c1c1c1',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, desejo deletar!',
        showLoaderOnConfirm: true,
        preConfirm: () => subActivitiesServices().del({ id }).then(api => {
          if (api.data.status === 200) {
            this.refresh()
            this.$swal.fire(
              'Deletado!',
              'O bloco foi apagado',
              'success'
            )
          }
        }).catch(err => {
          this.$swal.fire(
            'Ops!',
            'Erro ao tentar deletar.',
            'error'
          )
        })
          .finally(() => {
            this.isBusy = false
          }),
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Deletado!',
            'Congresso deletado com sucesso.',
            'success'
          )
        }
      })
    },
    listSpeakers() {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
      this.items = []
      this.isBusy = true
      subActivitiesServices().fetchSpeaker(params).then(data => {
        data.response.speakers.forEach(speaker => {
          this.speakers.push(speaker)
        })
        this.rows = data.response.speakers.length
      }).catch(err => console.log(err))
        .finally(() => { this.isBusy = false })
    },
    async openSpeaker(id, e) {
      this.selectedSpeaker = []
      subActivitiesServices().getSpeakerBlock({ events_blocks_id: id }).then(api => {
        api.forEach((speaker) => {
          this.selectedSpeaker.push(speaker.speaker)
        })
      })
      this.formBlockSpeakers.events_blocks_id = id
      this.$bvModal.show('bv-modal-palestrante')
    },
    async updateSpeaker() {
      this.formSpeakers = []

      await this.selectEventBlockspeakers.forEach((item) => {
        this.formSpeakers.push(item.id)
      })

      this.formBlockSpeakers.speakers = this.formSpeakers

      try {
        subActivitiesServices().updateSpeaker(this.formBlockSpeakers).then(api => {
          if (api.data.status === 400) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: api.data.message,
                variant: 'danger',
              }
            })
          } else if (api.data.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: api.data.message,
                icon: 'InfoIcon',
                variant: 'success',
              }
            })
          }

          this.$bvModal.hide('bv-modal-palestrante')
          this.selectEventBlockspeakers = []
        }).catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: err.message,
              variant: 'danger',
            }
          })
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: err.message,
            variant: 'danger',
          }
        })
      }
    },
    refresh() {
      subActivitiesServices().getSubActivities({ event_id: this.$route.params.id }).then(api => {
        this.items = api.data.response.blocks
      })
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
</style>
